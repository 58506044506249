export type Dict = {
    AmountDisplayString: string
    Bets: string,
    DK: string,
    MyBets: string,
    All: string,
    Open: string,
    Settled: string,
    Won: string,
    Lost: string,
    NoData: string,
    Wager: string,
    ToPay: string,
    ToPayUp: string,
    Paid: string,
    Result: string,
    BetsEnded: string,
    HidePicks: string,
    ViewPicks: string,
    Picks: string,
    PicksParlay: string,
    ProgressiveParlay: string,
    ProgressiveParlayMaxWin: string,
    ProgressiveParlayPossibleWin: string,
    ProgressiveParlayLost: string,
    ProgressiveParlayLostReturns: string
    ProgressiveParlayOutcomeHeader: string,
    ProgressiveParlayOddsHeader: string,
    ProgressiveParlayPayoutHeader: string,
    ProgressiverParlayVoidedSelecitonText: string,
    ReturnWager: string
    ProgIcon: string,
    RoundRobin: string,
    UnauthorizedBtnText: string,
    UnauthorizedMessageTitle: string,
    UnauthorizedMessageSubtitle: string,
    NoBetsBtnText: string,
    NoBetsMessageTitle: string,
    NoBetsMessageSubtitle: string,
    CashOutNewOfferMessage: string,
    CashOutOddsChangedMessage: string,
    CashOutGenericErrorMessage: string,
    CashOut: string,
    CashOutConfirm: string,
    CashOutSuspended: string,
    SGP: string,
    SGPExtra: string,
    CombinationPaginationText: string,
    Unknown: string,
    CashOutStatus: string,
    Cancelled: string,
    DeadHeatReduction: string,
    Void: string,
    ViewOlderBetsText: string,
    ViewOlderBetsBtnText: string,
    BoostPartiallyApplied: string,
    BonusCashedOutMessage: string,
    BonusDowngradedMessage: string,
    BonusReturnedMessage: string,
    BoostPartiallyApplied1Pick: string,
    BoostDetailsPicksTxt: string,
    BoostDetailsButtonText: string,
    BoostDetailsDescription: string,
    BoostDetailsBoostTxt: string,
    BoostDetailsDescriptionDowngrade: string,
    BoostDetailsDescriptionNotApplied: string,
    FreeBet: string,
    RiskFreeBet: string,
    OddsBoost: string,
    Boost: string,
    ParlayBoost: string,
    BonusInelligibleLabel: string,
    CashOutBonusDisclaimer: string,
    CashOutEarlyWinDisclaimer: string,
    CashOutQualifyingBetDisclaimer: string,
    DHRDisclaimer: string,
    LearnMore: string,
    EarlyWin: string,
    UP: string,
    EarlyWinNotApplied: string,
    EarlyWinReturned: string,
    EarlyWinCashedOut: string,
    QualifyingBet: string,
    QualifyingBetNotApplied: string,
    QualifyingBetReturned: string,
    QualifyingBetCashedOut: string,
    Total: string,
    Overtime: string,
    Runs: string,
    Hits: string,
    Point: string,
    First: string,
    Second: string,
    Third: string,
    Nth: string,
    Out: string,
    Top: string,
    Bot: string,
    And: string,
    BaseballPlaceholder: string,
    BaseballPlaceholderWithotuOuts: string,
    DefaultPlacholder: string,
    TimePlacholder: string,
    FirstHalf: string,
    BreakAfterFirstHalf: string,
    SecondHalf: string,
    RegularTimeEnd: string,
    AwaitingOverTime: string,
    FirstOvertime: string,
    BreakAfterFirstOvertime: string,
    SecondOvertime: string,
    BreakAfterSecondOvertime: string,
    OverTimeEnd: string,
    AwaitingPenalties: string,
    PenaltyShootout: string,
    PenaltyShootoutEnd: string,
    Finished: string,
    FirstQuarter: string,
    BreakAfterFirstQuarter: string,
    SecondQuarter: string,
    BreakAfterSecondQuarter: string,
    ThirdQuarter: string,
    BreakAfterThirdQuarter: string,
    FourthQuarter: string,
    BreakAfterFourthQuarter: string,
    ThirdOvertime: string,
    BreakAfterThirdOvertime: string,
    FourthOvertime: string,
    FirstSet: string,
    SecondSet: string,
    ThirdSet: string,
    FourthSet: string,
    FifthSet: string,
    FirstInning: string,
    SecondInning: string,
    ThirdInning: string,
    FourthInning: string,
    FifthInning: string,
    SixthInning: string,
    SeventhInning: string,
    EighthInning: string,
    NinthInning: string,
    TenthInning: string,
    EleventhInning: string,
    TwelfthInning: string,
    ThirteenthInning: string,
    FourteenthInning: string,
    FifteenthInning: string,
    SixteenthInning: string,
    SeventeenthInning: string,
    EighteenthInning: string,
    NineteenthInning: string,
    TwentiethInning: string,
    TwentyFirstInning: string,
    TwentySecondInning: string,
    TwentyThirdnning: string,
    TwentyFourthInning: string,
    TwentyFifthInning: string,
    TwentySixthInning: string,
    TwentySeventhInning: string,
    TwentyEightthInning: string,
    TwentyNinethInning: string,
    ThirtiethInning: string,
    BreakAfterFirstInning: string,
    BreakAfterSecondInning: string,
    BreakAfterThirdInning: string,
    BreakAfterFourthInning: string,
    BreakAfterFifthInning: string,
    BreakAfterSixthInning: string,
    BreakAfterSeventhInning: string,
    BreakAfterEighthInning: string,
    BreakAfterNinthInning: string,
    BreakAfterTenthInning: string,
    BreakAfterEleventhInning: string,
    BreakAfterTwelfthInning: string,
    BreakAfterThirteenthInning: string,
    BreakAfterFourteenthInning: string,
    BreakAfterFifteenthInning: string,
    BreakAfterSixteenthInning: string,
    BreakAfterSeventeenthInning: string,
    BreakAfterEighteenthInning: string,
    BreakAfterNineteenthInning: string,
    BreakAfterTwentiethInning: string,
    BreakAfterTwentyFirstInning: string,
    BreakAfterTwentySecondInning: string,
    BreakAfterTwentyThirdnning: string,
    BreakAfterTwentyFourthInning: string,
    BreakAfterTwentyFifthInning: string,
    BreakAfterTwentySixthInning: string,
    BreakAfterTwentySeventhInning: string,
    BreakAfterTwentyEightthInning: string,
    BreakAfterTwentyNinethInning: string,
    FirstPeriod: string,
    BreakAfterFirstPeriod: string,
    SecondPeriod: string,
    BreakAfterSecondPeriod: string,
    ThirdPeriod: string,
    FirstGame: string,
    BreakAfterFirstGame: string,
    SecondGame: string,
    BreakAfterSecondGame: string,
    ThirdGame: string,
    BreakAfterThirdGame: string,
    FourthGame: string,
    BreakAfterFourthGame: string,
    FifthGame: string,
    BreakAfterFifthGame: string,
    SixthGame: string,
    BreakAfterSixthGame: string,
    SeventhGame: string,
    OverTime: string,
    FinalScore: string,
}

export const dict: Dict = {
    AmountDisplayString: ":",
    Bets: "Bets",
    DK: "DK",
    MyBets: "My Bets",
    All: "All Bets",
    Open: "Open",
    Settled: "Settled",
    Won: "Won",
    Lost: "Lost",
    NoData: "No Data",
    Wager: "Wager",
    ToPay: "To Pay",
    ToPayUp: "To Pay Up To",
    Paid: "Paid",
    Result: "Result:",
    BetsEnded: "Bet Ended",
    HidePicks: "Hide Picks",
    ViewPicks: "View Picks",
    Picks: "Picks",
    PicksParlay: "Picks Parlay",
    ProgressiveParlay: "Pick Progressive Parlay",
    ProgressiveParlayMaxWin: "All {0} Legs Win",
    ProgressiveParlayPossibleWin: "{0} of {1} Legs Win",
    ProgressiveParlayLost: "Less Than {0} Legs Win",
    ProgressiveParlayLostReturns: "Bet Losses",
    ProgressiveParlayOutcomeHeader: "Outcome",
    ProgressiveParlayOddsHeader: "Odds",
    ProgressiveParlayPayoutHeader: "Payout",
    ProgressiverParlayVoidedSelecitonText: "One or more of the picks has voided. Your new odds are reflected below",
    ReturnWager: "RETURN WAGER",
    ProgIcon: "PROG",
    RoundRobin: "PICKS ROUND ROBIN",
    UnauthorizedBtnText: "Log In",
    UnauthorizedMessageTitle: "You're not logged in",
    UnauthorizedMessageSubtitle: "Log In to view your bet history",
    NoBetsBtnText: "View Game Lines",
    NoBetsMessageTitle: "No Bets were found",
    NoBetsMessageSubtitle: "You didn't place any bets yet",
    CashOutNewOfferMessage: "The odds changed during cash out, please try again.",
    CashOutOddsChangedMessage: "The odds changed during cash out, please try again.",
    CashOutGenericErrorMessage: "There was an error during cash out, please try again.",
    CashOut: "Cash Out",
    CashOutConfirm: "Confirm",
    CashOutSuspended: "Cash Out Suspended",
    SGP: "SGP",
    SGPExtra: "SGPx",
    CombinationPaginationText: "out of",
    Unknown: "Unknown",
    CashOutStatus: "Cashed Out",
    Cancelled: "Cancelled",
    DeadHeatReduction: "Won",
    Void: "Voided",
    ViewOlderBetsText: "For optimal experience, older bets have been hidden.",
    ViewOlderBetsBtnText: "View Older Bets",
    BonusCashedOutMessage: "* Boost was not applied since the bet was cashed out",
    BonusDowngradedMessage: "* Boost lowered due to voided picks",
    BonusReturnedMessage: "* The Boost was not applied. It will be returned to use again if the promotion is still active.",
    BoostPartiallyApplied: "* Boost applied on {0} picks",
    BoostPartiallyApplied1Pick: "* Boost applied on {0} pick",
    BoostDetailsPicksTxt: "# of Picks",
    BoostDetailsButtonText: "Got It",
    BoostDetailsDescription: "Boost your winnings by applying the Profit Boost to any qualifying parlay. The boost percentage you’ll receive is based on eligible legs and the table below.",
    BoostDetailsBoostTxt: "Boost %",
    BoostDetailsDescriptionDowngrade: `Some of your picks have been voided, or are ineligible for parlay boost. 
    Therefore, your boost was downgraded from {0} to {1} picks boost.`,
    BoostDetailsDescriptionNotApplied: `Some of your picks have been voided, or are ineligible for parlay boost. 
    Therefore, your boost was not applied.`,
    FreeBet: "Bonus Bet",
    RiskFreeBet: "Risk Bonus Bet",
    OddsBoost: "Odds Boost",
    Boost: "Boost",
    ParlayBoost: "Parlay Boost",
    BonusInelligibleLabel: "Parlay Boost Inelligible",
    CashOutBonusDisclaimer: "If the bet is cashed out the boost will be cancelled",
    CashOutEarlyWinDisclaimer: "If the bet is cashed out the early payout will be cancelled",
    CashOutQualifyingBetDisclaimer: "If the bet is cashed out the promo will be forfeited",
    DHRDisclaimer: "Dead Heat has been applied to this bet. Dead Heat occurs when two or more participants finish with the same result.",
    LearnMore: "Learn more",
    UP: "UP",
    EarlyWin: "Early Win",
    EarlyWinNotApplied: "Early win was not applied",
    EarlyWinReturned: "Early win was not applied. It was credited back to your account to use on another bet.",
    EarlyWinCashedOut: "Early win was not applied since the bet was cashed out",
    QualifyingBet: "{0}",
    QualifyingBetNotApplied: "{0}! A Bonus Bet was credited to your account",
    QualifyingBetReturned: "{0} promo was not applied. It was credited back to your account to use on another bet",
    QualifyingBetCashedOut: "{0} promo was not applied since the bet was cashed out",
    Total: "T",
    Overtime: "OT",
    Runs: "R",
    Hits: "H",
    Point: "P",
    First: "1st",
    Second: "2nd",
    Third: "3rd",
    Nth: "th",
    Out: "Out",
    Top: "TOP",
    Bot: "BOT",
    And: "&",
    BaseballPlaceholder: "{0} {1}, {2}",
    BaseballPlaceholderWithotuOuts: "{0} {1}",
    DefaultPlacholder: "{0}",
    TimePlacholder: "{time} {0}",
    FirstHalf: "1ST HALF",
    BreakAfterFirstHalf: "HALFTIME",
    SecondHalf: "2ND HALF",
    RegularTimeEnd: "OVERTIME",
    AwaitingOverTime: "OVERTIME",
    FirstOvertime: "OVERTIME",
    BreakAfterFirstOvertime: "2ND OVERTIME",
    SecondOvertime: "2ND OVERTIME",
    BreakAfterSecondOvertime: "3RD OVERTIME",
    OverTimeEnd: "FULL TIME",
    AwaitingPenalties: "PENALTIES",
    PenaltyShootout: "PENALTIES",
    PenaltyShootoutEnd: "FULL TIME",
    Finished: "FULL TIME",
    FirstQuarter: "1ST QUARTER",
    BreakAfterFirstQuarter: "2ND QUARTER",
    SecondQuarter: "2ND QUARTER",
    BreakAfterSecondQuarter: "HALFTIME",
    ThirdQuarter: "3RD QUARTER",
    BreakAfterThirdQuarter: "4TH QUARTER",
    FourthQuarter: "4TH QUARTER",
    BreakAfterFourthQuarter: "OVERTIME",
    ThirdOvertime: "3RD OVERTIME",
    BreakAfterThirdOvertime: "4TH OVERTIME",
    FourthOvertime: "4TH OVERTIME",
    FirstSet: "1ST SET",
    SecondSet: "2ND SET",
    ThirdSet: "3RD SET",
    FourthSet: "4TH SET",
    FifthSet: "5TH SET",
    FirstInning: "1ST",
    SecondInning: "2ND",
    ThirdInning: "3RD",
    FourthInning: "4TH",
    FifthInning: "5TH",
    SixthInning: "6TH",
    SeventhInning: "7TH",
    EighthInning: "8TH",
    NinthInning: "9TH",
    TenthInning: "10TH",
    EleventhInning: "11TH",
    TwelfthInning: "12TH",
    ThirteenthInning: "13TH",
    FourteenthInning: "14TH",
    FifteenthInning: "15TH",
    SixteenthInning: "16TH",
    SeventeenthInning: "17TH",
    EighteenthInning: "18TH",
    NineteenthInning: "19TH",
    TwentiethInning: "20TH",
    TwentyFirstInning: "21TH",
    TwentySecondInning: "22TH",
    TwentyThirdnning: "23TH",
    TwentyFourthInning: "24TH",
    TwentyFifthInning: "25TH",
    TwentySixthInning: "26TH",
    TwentySeventhInning: "27TH",
    TwentyEightthInning: "28TH",
    TwentyNinethInning: "29TH",
    ThirtiethInning: "30TH",
    BreakAfterFirstInning: "2ND",
    BreakAfterSecondInning: "3RD",
    BreakAfterThirdInning: "4TH",
    BreakAfterFourthInning: "5TH",
    BreakAfterFifthInning: "6TH",
    BreakAfterSixthInning: "7TH",
    BreakAfterSeventhInning: "8TH",
    BreakAfterEighthInning: "9TH",
    BreakAfterNinthInning: "10TH",
    BreakAfterTenthInning: "11TH",
    BreakAfterEleventhInning: "12TH",
    BreakAfterTwelfthInning: "13TH",
    BreakAfterThirteenthInning: "14TH",
    BreakAfterFourteenthInning: "15TH",
    BreakAfterFifteenthInning: "16TH",
    BreakAfterSixteenthInning: "17TH",
    BreakAfterSeventeenthInning: "18TH",
    BreakAfterEighteenthInning: "19TH",
    BreakAfterNineteenthInning: "20TH",
    BreakAfterTwentiethInning: "21TH",
    BreakAfterTwentyFirstInning: "22TH",
    BreakAfterTwentySecondInning: "23TH",
    BreakAfterTwentyThirdnning: "24TH",
    BreakAfterTwentyFourthInning: "25TH",
    BreakAfterTwentyFifthInning: "26TH",
    BreakAfterTwentySixthInning: "27TH",
    BreakAfterTwentySeventhInning: "28TH",
    BreakAfterTwentyEightthInning: "29TH",
    BreakAfterTwentyNinethInning: "30TH",
    FirstPeriod: "1ST PERIOD",
    BreakAfterFirstPeriod: "1ST INTERMISSION",
    SecondPeriod: "2ND PERIOD",
    BreakAfterSecondPeriod: "2ND INTERMISSION",
    ThirdPeriod: "3RD PERIOD",
    FirstGame: "1ST GAME",
    BreakAfterFirstGame: "2ND GAME",
    SecondGame: "2ND GAME",
    BreakAfterSecondGame: "3RD GAME",
    ThirdGame: "3RD GAME",
    BreakAfterThirdGame: "4TH GAME",
    FourthGame: "4TH GAME",
    BreakAfterFourthGame: "5TH GAME",
    FifthGame: "5TH GAME",
    BreakAfterFifthGame: "6TH GAME",
    SixthGame: "6TH GAME",
    BreakAfterSixthGame: "7TH GAME",
    SeventhGame: "7TH GAME",
    OverTime: "OVERTIME",
    FinalScore: "Final Score"
}